import Q from 'q'
// 改签订单列表
import trains_order_buyerChangeOrderList from '@/lib/data-service/trains/trains_order_buyerChangeOrderList.js';
// 取消改签
import trains_order_buyerCancelChangeOrder from '@/lib/data-service/trains/trains_order_buyerCancelChangeOrder.js';
// 城市插件
import TrainCitySelector from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/train-city-selector/1.1.0/index.vue';
// // 获取订单支付状态
import trains_order_changeOrderPaymentStatus from "@/lib/data-service/trains/trains_order_changeOrderPaymentStatus.js";
import moment from 'moment';

export default {
  components: {
    TrainCitySelector
  },
  data () {
    return {
      form: {
        currentPage: 1,
        pageSize: 10,
        orderNo: '',
        changeNo: '',
        changeTrainCode: '',
        originalTrainCode: '',
        changeFromStationSn: '',
        changeToStationSn: '',
        passengerName: '',
        orderChangeStatus: '',
        beginOriginalChangeStartDate: '',
        originalEndChangeStartDate: '',

        beginChangeStartDate: '',
        endChangeStartDate: '',
      },
      changeDepartDate: '',
      departDate: '',
      fromCity: {},
      toCity: {},
      list: [],
      loading: false,
      timer: null,
      allocation: {
        statusOptions: [
          { label: '', text: '全部' },
          { label: '1', text: '待支付' },
          { label: '2', text: '改签中' },
          { label: '3', text: '改签成功' },
          { label: '4', text: '改签失败' },
          { label: '5', text: '取消' },

        ]
      },
      pager: {
        pageSizes: [10,20,30,40,50],
        total: 0,
      }
    }
  },
  methods: {
    req_list (params) {
      clearInterval(this.timer);
      this.loading = true;
      const _this = this;
      _this.list = [];
      Q.when()
        .then(function () {
          return trains_order_buyerChangeOrderList(params);
        })
        .then(function (res) {
          const list = res.pageResult.pageData;
          list.forEach(function (item) {
            item.changeStartDate = moment(item.changeStartDate).format('YYYY-MM-DD HH:mm');
            item.changeArriveDate = moment(item.changeArriveDate).format('YYYY-MM-DD HH:mm');
            item.originalStartDate = moment(item.originalStartDate).format('YYYY-MM-DD HH:mm');
            item.originalArriveDate = moment(item.originalArriveDate).format('YYYY-MM-DD HH:mm');
            if (moment(item.changeStartDate).format('YYYY-MM-DD') === moment(item.changeArriveDate).format('YYYY-MM-DD')) {
              item.changeDate = moment(item.changeStartDate).format('YYYY-MM-DD');
              item.changeTime =  moment(item.changeStartDate).format('HH:mm') + '-' + moment(item.changeArriveDate).format('HH:mm');
            }
            if (moment(item.originalStartDate).format('YYYY-MM-DD') === moment(item.originalArriveDate).format('YYYY-MM-DD')) {
              item.originDate = moment(item.originalStartDate).format('YYYY-MM-DD');
              item.originTime =  moment(item.originalStartDate).format('HH:mm') + '-' + moment(item.originalArriveDate).format('HH:mm');
            }
          })
          _this.count_down(list);
          _this.list = list;

          _this.pager.total = res.pageResult.totalCount;
          _this.loading = false;
          _this.timer = setInterval(function () {
            _this.count_down(_this.list);
          }, 1000);
        })
    },
    req_cancel_change (params) {
      const _this = this;
      Q.when()
        .then(function () {
          return trains_order_buyerCancelChangeOrder(params);
        })
        .then(function (res) {
          _this.$message.success('已取消改签');
          _this.req_list(_this.form);
        })
    },
    req_payment_status (params) {
      return Q.when()
        .then(function () {
          return trains_order_changeOrderPaymentStatus(params);
        })
    },
    count_down (list) {
      const _this = this;
      list.forEach(function (item) {

        if (item.changeOderStatus == 1) {
          const now = moment();
          item.second = moment(item.gmtLastPayment).diff(moment(now), 'seconds');

          item.secondText = item.second > 0 ? Math.floor(item.second / 60) + '分' + item.second % 60 + '秒' : '00分00秒';
          if (item.second === 0) _this.req_list(_this.form);
        } else {
          item.secondText = '--'
        }
      })
    },
    search () {
      this.form.currentPage = 1;
      this.req_list(this.form);
    },
    reset () {
      this.changeDepartDate = '';
      this.departDate = '';
      this.fromCity = {};
      this.toCity = {};
      this.form = {
        currentPage: 1,
        pageSize: 10,
        orderNo: '',
        changeNo: '',
        changeTrainCode: '',
        originalTrainCode: '',
        changeFromStationSn: '',
        changeToStationSn: '',
        passengerName: '',
        orderChangeStatus: '',
        beginOriginalChangeStartDate: '',
        originalEndChangeStartDate: '',
        beginChangeStartDate: '',
        endChangeStartDate: '',
      };
      this.req_list(this.form);
    },
    change_status (orderStatus) {
      this.form.orderChangeStatus = orderStatus;
      this.form.currentPage = 1;
      this.req_list(this.form);
    },
    cancle_change (changeNo) {
      const _this = this;
      this.$confirm('是否确定取消改签?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.req_cancel_change({changeNo});
      }).catch(() => {

      });

    },
    toPayment (orderNo) {
      const _this = this;
      Q.when()
        .then(function () {
          return _this.req_payment_status({orderNo});
        })
        .then(function (res) {
          if (res.ifTimeout === 1) {
            _this.$router.push({
              name: 'distributor-train-front-train-change-payment',
              query: { orderNo, type: 'change' },
            })
          } else if (res.ifTimeout === 2) {
            _this.req_list(_this.form);
          }
        })
    },
    to_detail (changeNo) {
      this.$router.push({
        name: 'distributor-train-back-train-change-detail',
        query: {
          changeNo,
        }
      })
    },
    change_currentPage (currentPage) {
      this.form.currentPage = currentPage;
      this.req_list(this.form);
    },
    change_pageSizes (pageSize) {
      this.form.pageSize = pageSizes;
      this.form.currentPage = 1;
      this.req_list(this.form);
    },
    return_red (row) {
      if (row.columnIndex === 11) return 'red';
    }
  },
  mounted () {

  },
  activated () {
    this.req_list(this.form);
  },
  deactivated () {
    clearInterval(this.timer);
  },
  watch: {
    changeDepartDate (val) {
      if (val) {
        this.form.beginChangeStartDate = val[0] + ' 00:00:00';
        this.form.endChangeStartDate = val[1] + ' 23:59:59';
      } else {
        this.form.beginChangeStartDate = '';
        this.form.endChangeStartDate = '';
      }
    },
    departDate (val) {
      if (val) {
        this.form.beginOriginalChangeStartDate = val[0] + ' 00:00:00';
        this.form.originalEndChangeStartDate = val[1] + ' 23:59:59';
      } else {
        this.form.beginOriginalChangeStartDate = '';
        this.form.originalEndChangeStartDate = '';
      }
    },
    fromCity (val) {
      if (val) {
        this.form.changeFromStationSn = val.sn;
      } else {
        this.form.changeFromStationSn = '';
      }
    },
    toCity (val) {
      if (val) {
        this.form.changeToStationSn = val.sn;
      } else {
        this.form.changeToStationSn = '';
      }
    }
  }
}
